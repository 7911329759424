export const drawRequestFields = [
  'id',
  'approved_amount',
  'coordinator{id,full_name}',
  'current_reviewer_team{id,name}',
  'customer',
  'days_since_submitted',
  'disbursed_at',
  'variance_to_lender_allowance_rate',
  'inspection',
  'inspector_allowance_rate',
  'lender_allowance_rate',
  'number',
  'project{id,status,status_change_reason,name,address,type,overall_score,borrower_name,lender_name,investor_name,loan{external_id}}',
  'requested_amount',
  'revised_estimate',
  'status',
  'submitted_at',
  'type',
  'next_review_role',
  'waits_current_user_approval',
  'is_resubmit',
  'is_resubmit_change_reason',
  'is_on_hold',
  'is_on_hold_change_reason',
  'comments_preview',
];

export const drawRequestListFields = [
  'id',
  'counter_per_request_type',
  'number',
  'type',
  'status',
  'waits_current_user_approval',
  'team',
  'approved_amount',
  'approved_amount_rate',
  'lender_allowance_rate',
  'approved_amount_cumulative',
  'submitted_at',
  'disbursed_amount',
  'disbursed_at',
  'borrower_equity',
  'construction_holdback',
  'is_resubmit',
  'is_resubmit_change_reason',
  'is_on_hold',
  'is_on_hold_change_reason',
  'approved_reallocation',
  'approved_budget_change',
  'revised_estimate',
  'estimated_disbursement_date',
  'source',
  'requested_amount',
  'retainage_approved_amount_holdback',
  'retainage_release_approved',
  'borrower_equity_fees',
  'borrower_equity_with_fees',
  'construction_holdback_with_fees',
  'fees_amount',
];

export const commentsPreviewQueryFields = '{id,comments_preview}';

export const drawRequestReportQuery = `{
approved_at,
current_reviewer_team,
gap_before,
inspector_allowance_rate_before,
lender_allowance_rate_incremental,
next_review_role,
number,
status,
submitted_at,
totals{all},
type,
is_resubmit,
is_resubmit_change_reason,
is_on_hold,
is_on_hold_change_reason,
}`;

export const excludeCommentsQueryFields = '{-comments_preview}';
export const excludeCommentsWithTotalsAllQueryFields =
  '{-comments_preview,totals{all,current{approved_amount,retainage_release_approved,requested_amount}}}';
export const drawRequestQueryFields =
  'requested_reallocation,requested_reallocation_rate,approved_reallocation,approved_reallocation_rate,lender_allowance_rate_incremental,providers,errors,retainage_approved_amount_holdback,retainage_requested_amount_holdback,can_be_deleted';

export const inspectionListQueryFields =
  '{-milestones,-photos,-report,project{id},-totals,-draw_request_totals}';

export const serviceOrdersListQueryFields = '{*,project{id}}';
export const allProjectsServiceOrdersListQueryFields =
  '{*project{id,name,status,loan{external_id}}}';

export const onlyProgressPhotos = '&only_progress_photos=true';

export const projectListFields = [
  'address{address_1,full_address,city,state}',
  'archived_at',
  'borrower_name',
  'construction_holdback_available',
  'construction_holdback_current',
  'construction_holdback_total',
  'coordinator{full_name}',
  'customer{name}',
  'draw_request',
  'duration',
  'gap',
  'id',
  'inspector_allowance_rate',
  'investor_name',
  'is_watching',
  'lender_allowance_rate',
  'lender_name',
  'loan{external_id,loc_commitment,close_date,maturity_date,extended_maturity_date,type}',
  'name',
  'project_funds{original_estimate,revised_estimate}',
  'project_type',
  'property_existing_type',
  'property_proposed_type',
  'scores',
  'status',
  'thumb_representations',
  'watcher_count',
  'is_budget_locked',
];

export const commentsListFields = [
  'commenter',
  'content_type',
  'created_at',
  'created_by{company{id,name},full_name}',
  'has_children',
  'id',
  'is_external_comment',
  'message',
  'parent_id',
  'pinned_at',
  'received_by_company{id,name}',
  'tags',
  'team{name,company{name}}',
];
